html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #FAFBFF;
  line-height: 20px;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

body * {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

h1, h2, h3, h4, h5, h6,
p {
  margin: 0;
  line-height: 1.5;
}

h1 {
  font-weight: 300;
  letter-spacing: 1px;
}

a {
  color: #597FB3;
  text-decoration: none;
}

input,
select,
textarea,
button {
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

aside {
  display: block;
  padding: 0.5em 20%;
  margin: 4px 0 0 0;
  font-size: 80%;
  font-family: Arial;
  color: #666;
  background: #F8F8F8;
  border-top: solid 1px #D0D6DE;
  border-bottom: solid 1px #D0D6DE;
  text-align: center;
}

ul {
  margin-bottom: 0;
}
